'use client';

import React from 'react';
import { useTokens, Text, Flex, Box, Heading } from '@mezzoforte/forge';
import { PrismicRichText } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import styled from '@emotion/styled';
import { EntryListItem } from '@/components/EntryList/EntryListItem';
import { PrismicLinkButton } from '@/components/Link/LinkButton';
import { gtmService } from '@/util/gtm-service';
import type {
  CategoryPageDocumentDataBannersItem,
  ClassPageDocumentDataBannersItem,
  FrontPageDocumentDataBannersItem,
} from 'prismicio-types';

export type PrismicBannerItem =
  | FrontPageDocumentDataBannersItem
  | CategoryPageDocumentDataBannersItem
  | ClassPageDocumentDataBannersItem;

const { breakpoints } = useTokens.huutokaupat;

const VideoContainer = styled.div`
  aspect-ratio: 16 / 9;
  height: 100%;
  max-width: 100%;

  iframe {
    display: block;
    min-height: 300px;
    height: 100%;
    width: 100%;
  }
`;

function imageSizeAttribute({ type, media_type }: PrismicBannerItem) {
  if (type === 'Small') return `(max-width: ${breakpoints.md}) 100wv, 405px`;
  if (type === 'Large' && ['Background', 'Dark Background'].includes(media_type)) return `100wv`;
  return `(max-width: 648px) 100wv, 33wv`;
}

function EntryListBannerInner(banner: PrismicBannerItem) {
  const isGrid = banner.type === 'Large' && ['Left', 'Right'].includes(banner.media_type);
  const hasMedia = banner.image.url ?? banner.video.html;

  return (
    <Box
      backgroundColor="brandSecondary"
      borderRadius={8}
      color="background"
      display={isGrid ? 'grid' : 'flex'}
      gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
      gridColumnGap={isGrid ? '1rem' : 0}
      margin={{ base: '1rem', md: 0 }}
      overflow="hidden"
      position="relative"
      width="100%"
    >
      {hasMedia && (
        <Box
          minHeight={isGrid ? 270 : 0}
          position={isGrid ? 'relative' : undefined}
          order={banner.media_type === 'Right' && isGrid ? 1 : 0}
        >
          {!banner.video.embed_url && banner.image.url && (
            <PrismicNextImage
              field={banner.image}
              fallbackAlt=""
              fill
              sizes={imageSizeAttribute(banner)}
              style={{
                filter: banner.media_type === 'Dark Background' ? 'brightness(0.3)' : undefined,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          )}
          {isGrid && banner.video.html && <VideoContainer dangerouslySetInnerHTML={{ __html: banner.video.html }} />}
        </Box>
      )}
      <Flex
        alignItems="center"
        flexDir="column"
        flexGrow={1}
        justifyContent="center"
        zIndex={1}
        padding="2rem 1rem"
        order={banner.media_type === 'Right' && isGrid ? 0 : 1}
      >
        {/* TODO: Icon fix */}
        {/* {banner.icon && <Icon name={banner.icon} sizing="large" />} */}
        <PrismicRichText
          field={banner.title}
          components={{
            heading3: ({ key, ...props }) => (
              <Heading
                variant="h3"
                color="background"
                fontSize={20}
                textAlign="center"
                mt={3}
                {...props}
                key={key}
              />
            ),
          }}
        />
        <PrismicRichText
          field={banner.text}
          components={{
            paragraph: ({ key, ...props }) => (
              <Text
                color="background"
                textAlign="center"
                mt={3}
                {...props}
                key={key}
              />
            ),
          }}
        />
        {banner.call_to_action_link_text && (
          <PrismicLinkButton
            variant="primary-hero"
            field={banner.call_to_action_link}
            mt={3}
            onClick={() =>
              gtmService.recommended.selectContent('cta', 'front_page_banner_button', {
                link_text: banner.call_to_action_link_text,
                link_url: banner.call_to_action_link,
              })
            }
          >
            {banner.call_to_action_link_text}
          </PrismicLinkButton>
        )}
      </Flex>
    </Box>
  );
}

export function EntryListBanner(banner: PrismicBannerItem) {
  if (banner.type === 'Large') {
    return (
      <EntryListItem
        gridColumn="1 / -1"
        height="auto"
      >
        <EntryListBannerInner {...banner} />
      </EntryListItem>
    );
  }

  return (
    <EntryListItem>
      <EntryListBannerInner {...banner} />
    </EntryListItem>
  );
}
