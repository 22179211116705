'use client';

import { useEffect, useState } from 'react';
import { useClientRendering } from '@/hooks/useClientRendering';
import useSendAnalytics from '@/hooks/useSendAnalytics';
import { useCategories } from '@/hooks/useCategories';
import { gtmService } from '@/util/gtm-service';
import { listEntryToEcommerceItem } from '@/util/ecommerce';
import { ListEntry } from '@/types/ListEntry';

interface FrontPageAnalyticsProps {
  entries: ListEntry[];
}

export function FrontPageAnalytics({ entries }: FrontPageAnalyticsProps) {
  const [isSent, setIsSent] = useState(false);
  const sendAnalytics = useSendAnalytics();
  const isClient = useClientRendering();
  const { data: categoriesData } = useCategories();

  if (!isSent && isClient) {
    sendAnalytics('frontPageView');
    setIsSent(true);
  }

  useEffect(() => {
    if (categoriesData === undefined) {
      return;
    }

    gtmService.recommendedEcommerce.viewItemList(
      'front_page',
      'Front page',
      entries.map((entry, index) => listEntryToEcommerceItem(entry, categoriesData.categories, index))
    );
  }, [entries, categoriesData]);

  return null;
}
